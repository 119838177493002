import React from 'react'
import PropTypes from 'prop-types'

const Success = (props) => {
  const { setSelectedImage, setShowSuccess } = props

  const resetEverything = () => {
    setSelectedImage(0)
    setShowSuccess(false)
  }

  return (
    <div className='postcard-form-success-message'>
      <button
        className='button back-to-card-overview'
        type='button'
        onClick={() => resetEverything()}>
        zurück zur Auswahl der Postkarte
      </button>
      <span className='thanks'>Vielen Dank!</span>
      <br />
      <span>Die Postkarte wurde erfolgreich an den Empfänger verschickt.</span>
    </div>
  )
}

Success.propTypes = {
  setSelectedImage: PropTypes.func.isRequired,
  setShowSuccess: PropTypes.func.isRequired
}

export default Success
