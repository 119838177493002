import React from 'react'
import PropTypes from 'prop-types'

const Form = (props) => {
  const {
    handleFormSubmit,
    setShowForm,
    setRecipient,
    setName,
    setMail,
    setNewsletter,
    setMessage,
    recipientError,
    nameError,
    mailError,
    messageError,
    setRecipientError,
    setNameError,
    setMailError,
    setMessageError
  } = props

  const handleChange = (target) => {
    if (target.name === 'recipient') {
      setRecipient(target.value)
      setRecipientError('')
    }
    if (target.name === 'name') {
      setName(target.value)
      setNameError('')
    }
    if (target.name === 'mail') {
      setMail(target.value)
      setMailError('')
    }
    if (target.name === 'newsletter') {
      setNewsletter(target.checked)
    }
    if (target.name === 'message') {
      setMessage(target.value)
      setMessageError('')
    }
  }

  return (
    <form className='postcard-form'>
      <button
        className='button back-to-card-overview'
        type='button'
        onClick={() => setShowForm(false)}>
        zurück zur Auswahl der Postkarte
      </button>
      <div className='postcard-form__left'>
        <label htmlFor='recipient'>
          <span>E-Mail-Adresse des Empfängers</span>
          <input
            className={recipientError && 'has-error'}
            type='email'
            id='recipient'
            name='recipient'
            autoComplete='off'
            onChange={(e) => handleChange(e.target)}
          />
          {recipientError && <span className='error'>{recipientError}</span>}
        </label>
        <label htmlFor='name'>
          <span>Dein Name</span>
          <input
            className={nameError && 'has-error'}
            type='text'
            id='name'
            name='name'
            autoComplete='off'
            onChange={(e) => handleChange(e.target)}
          />
          {nameError && <span className='error'>{nameError}</span>}
        </label>
        <label htmlFor='mail'>
          <span>Deine E-Mail-Adresse</span>
          <input
            className={mailError && 'has-error'}
            type='email'
            id='mail'
            name='mail'
            autoComplete='off'
            onChange={(e) => handleChange(e.target)}
          />
          {mailError && <span className='error'>{mailError}</span>}
        </label>
        <label htmlFor='newsletter' className='checkbox-wrapper'>
          <input
            type='checkbox'
            id='newsletter'
            name='newsletter'
            autoComplete='off'
            onChange={(e) => handleChange(e.target)}
          />
          <span>Ich möchte den Welterbe Newsletter erhalten, dieser kommt viermal jährlich.</span>
        </label>
      </div>
      <div className='postcard-form__right'>
        <label htmlFor='message'>
          <span>Deine Nachricht an den Empfänger</span>
          <textarea
            className={messageError && 'has-error'}
            id='message'
            name='message'
            autoComplete='off'
            onChange={(e) => handleChange(e.target)}
          />
          {messageError && <span className='error'>{messageError}</span>}
        </label>
        <button
          className='button send-carpet-mail-submit-button'
          type='submit'
          onClick={(e) => handleFormSubmit(e)}>
          Postkarte verschicken
        </button>
      </div>
    </form>
  )
}

Form.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
  setRecipient: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setMail: PropTypes.func.isRequired,
  setNewsletter: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  recipientError: PropTypes.string.isRequired,
  nameError: PropTypes.string.isRequired,
  mailError: PropTypes.string.isRequired,
  messageError: PropTypes.string.isRequired,
  setRecipientError: PropTypes.func.isRequired,
  setNameError: PropTypes.func.isRequired,
  setMailError: PropTypes.func.isRequired,
  setMessageError: PropTypes.func.isRequired
}

export default Form
