import React from 'react'
import PropTypes from 'prop-types'

const ImageOverlay = (props) => {
  const { setShowForm } = props

  return (
    <div className='postcard-selected'>
      <div>
        <svg
          width='74'
          height='58'
          viewBox='0 0 74 58'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M6.73334 29.8144L25.7522 48.8333L67.7522 6.83325'
            stroke='white'
            strokeWidth='12'
            strokeLinecap='round'
          />
        </svg>
        <span className='postcard-selected__message'>Postkarte ausgewählt</span>
        <button
          type='button'
          className='postcard-selected__button'
          onClick={() => setShowForm(true)}>
          Nachricht schreiben
        </button>
      </div>
    </div>
  )
}

ImageOverlay.propTypes = {
  setShowForm: PropTypes.func.isRequired
}

export default ImageOverlay
