/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Image from './components/Image'
import Form from './components/Form'
import Success from './components/Success'

const PostcardCreator = (props) => {
  const { images } = props
  const imagesJSON = JSON.parse(images)

  const [selectedImage, setSelectedImage] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const [recipient, setRecipient] = useState('')
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [newsletter, setNewsletter] = useState(false)
  const [message, setMessage] = useState('')

  const [recipientError, setRecipientError] = useState('')
  const [nameError, setNameError] = useState('')
  const [mailError, setMailError] = useState('')
  const [messageError, setMessageError] = useState('')

  const onImageSelection = (imageId) => {
    const id = parseInt(imageId, 10)
    setSelectedImage(id)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const data = {
      recipient,
      name,
      mail,
      newsletter,
      message,
      image: selectedImage
    }

    const response = await fetch('/wp-json/sardona/send-postcard/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const sendPostcard = await response.json()
    if (sendPostcard.success === true) {
      setShowForm(false)
      setShowSuccess(true)
    } else {
      const { errors } = sendPostcard
      if (errors.recipient !== true) {
        setRecipientError('Diese E-Mail-Adresse ist ungültig.')
      }
      if (errors.name !== true) {
        setNameError('Dieser Name ist ungültig.')
      }
      if (errors.mail !== true) {
        setMailError('Diese E-Mail-Adresse ist ungültig.')
      }
      if (errors.message !== true) {
        setMessageError('Diese Nachricht ist ungültig.')
      }
      console.error('Fehler beim Versenden des Mails', errors)
    }
  }

  return (
    <>
      {!showForm &&
        !showSuccess &&
        imagesJSON.map((image) => (
          <Image
            key={image.id}
            image={image}
            selectedImage={selectedImage}
            setShowForm={setShowForm}
            onImageSelection={onImageSelection}
          />
        ))}
      {showForm && (
        <Form
          handleFormSubmit={handleFormSubmit}
          setShowForm={setShowForm}
          setRecipient={setRecipient}
          setName={setName}
          setMail={setMail}
          setNewsletter={setNewsletter}
          setMessage={setMessage}
          recipientError={recipientError}
          nameError={nameError}
          mailError={mailError}
          messageError={messageError}
          setRecipientError={setRecipientError}
          setNameError={setNameError}
          setMailError={setMailError}
          setMessageError={setMessageError}
        />
      )}
      {showSuccess && (
        <Success setSelectedImage={setSelectedImage} setShowSuccess={setShowSuccess} />
      )}
    </>
  )
}

PostcardCreator.propTypes = {
  images: PropTypes.string.isRequired
}

const domContainer = document.getElementById('postcardcreator')
if (domContainer) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  ReactDOM.render(<PostcardCreator {...domContainer.dataset} />, domContainer)
}
