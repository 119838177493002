import '../styles/main.scss'
import videojs from 'video.js'

import './PostcardCreator/App'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', () => {
  const videoplayer = document.getElementById('videoplayer')
  if (videoplayer) {
    videojs(videoplayer, {
      fluid: true
    })
  }

  const imageAnimations = [...document.querySelectorAll('.image-animation')]

  imageAnimations.forEach((element) => {
    element.addEventListener('click', () => {
      element.classList.toggle('active')
    })
  })
})
